define("discourse/plugins/discourse-solved/discourse/components/solved-accept-answer-button", ["exports", "@glimmer/component", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _service, _dButton, _ajax, _ajaxError, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.acceptAnswer = acceptAnswer;
  _exports.default = void 0;
  class SolvedAcceptAnswerButton extends _component.default {
    static hidden(args) {
      return args.post.topic_accepted_answer;
    }
    static #_ = (() => dt7948.g(this.prototype, "appEvents", [_service.service]))();
    #appEvents = (() => (dt7948.i(this, "appEvents"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "currentUser", [_service.service]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    get showLabel() {
      return this.currentUser?.id === this.args.post.topicCreatedById;
    }
    acceptAnswer() {
      acceptAnswer(this.args.post, this.appEvents);
    }
    static #_3 = (() => dt7948.n(this.prototype, "acceptAnswer", [_object.action]))();
    static #_4 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <DButton
          class="post-action-menu__solved-unaccepted unaccepted"
          ...attributes
          @action={{this.acceptAnswer}}
          @icon="far-square-check"
          @label={{if this.showLabel "solved.solution"}}
          @title="solved.accept_answer"
        />
      
    */
    {
      "id": "pozREvFz",
      "block": "[[[1,\"\\n    \"],[8,[32,0],[[24,0,\"post-action-menu__solved-unaccepted unaccepted\"],[17,1]],[[\"@action\",\"@icon\",\"@label\",\"@title\"],[[30,0,[\"acceptAnswer\"]],\"far-square-check\",[52,[30,0,[\"showLabel\"]],\"solved.solution\"],\"solved.accept_answer\"]],null],[1,\"\\n  \"]],[\"&attrs\"],false,[\"if\"]]",
      "moduleName": "/home/discourse/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-solved/discourse/components/solved-accept-answer-button.js",
      "scope": () => [_dButton.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = SolvedAcceptAnswerButton;
  function acceptAnswer(post, appEvents) {
    // TODO (glimmer-post-menu): Remove this exported function and move the code into the button action after the widget code is removed
    acceptPost(post);
    appEvents.trigger("discourse-solved:solution-toggled", post);
    post.get("topic.postStream.posts").forEach(p => {
      p.set("topic_accepted_answer", true);
      appEvents.trigger("post-stream:refresh", {
        id: p.id
      });
    });
  }
  function acceptPost(post) {
    const topic = post.topic;
    clearAccepted(topic);
    post.setProperties({
      can_unaccept_answer: true,
      can_accept_answer: false,
      accepted_answer: true
    });
    topic.set("accepted_answer", {
      username: post.username,
      name: post.name,
      post_number: post.post_number,
      excerpt: post.cooked
    });
    (0, _ajax.ajax)("/solution/accept", {
      type: "POST",
      data: {
        id: post.id
      }
    }).catch(_ajaxError.popupAjaxError);
  }
  function clearAccepted(topic) {
    const posts = topic.get("postStream.posts");
    posts.forEach(post => {
      if (post.get("post_number") > 1) {
        post.setProperties({
          accepted_answer: false,
          can_accept_answer: true,
          can_unaccept_answer: false,
          topic_accepted_answer: false
        });
      }
    });
  }
});